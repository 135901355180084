import { Component, OnInit } from '@angular/core';
// import { AuthService } from '../core/auth.service'
import { Router, Params } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthService } from '../../core/auth.service';
import { AngularFireFunctions } from '@angular/fire/functions';
import { HttpClient } from '@angular/common/http';
import { TempService } from 'src/app/core/services/temp.service';
import { languages } from 'src/assets/language';
import { AngularFireAuth } from '@angular/fire/auth';
// import { AuthService } from '../core/auth.service';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {

  registerForm: FormGroup;
  successMessage: string = '';
  sttLoading: boolean = false;
  sttNotifi: boolean = false;
  textNotifi: string;
  sttTextNotifi: string = 'toast-success';
  arrLanguage: any = {};
  language: string;

  dismissToast() {
    this.sttNotifi = false;
  }
  constructor(
    private tempService: TempService,
    public authService: AuthService,
    private router: Router,
    private fb: FormBuilder,
    private fbfs: AngularFireFunctions,
    private http: HttpClient,
    public afAuth: AngularFireAuth,
  ) {
    this.createForm();
    console.log(this.fbfs);

  }
  checkDataSubmit(value) {
    if (value.email == '') {
      this.sttNotifi = true;
      this.sttTextNotifi = 'toast-error';
      this.sttLoading = false;
      this.textNotifi = 'Invalid email';
      return false;
    }
    return true;
  }
  async submit(value) {
    console.log(value);
    this.sttLoading = true;
    if (!this.checkDataSubmit(value)) {
      this.sttLoading = false;
      return
    }
    this.afAuth.auth.sendPasswordResetEmail(value.email).then(a => {
      console.log('successs', a);
      this.sttNotifi = true;
      this.sttLoading = false;
      this.textNotifi = "Password reset email sent successfully";
      this.sttTextNotifi = 'toast-success';
    }).catch(er => {
      console.log('er ', er);
      this.sttNotifi = true;
      this.sttTextNotifi = 'toast-error';
      this.sttLoading = false;
      this.textNotifi = er.message;
    })
  }
  ngOnInit() {
    this.tempService.languageObs.subscribe(msg => {
      console.log(msg);
      if (msg != '') {
        console.log('msg is', languages[msg]);
        this.arrLanguage = languages[msg];
        this.language = msg;
      }
    })
  }
  createForm() {
    this.registerForm = this.fb.group({
      email: ['', Validators.required]
    });
  }

}
