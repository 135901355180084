import { Component, OnInit } from '@angular/core';
import { languages } from 'src/assets/language';
import { TempService } from 'src/app/core/services/temp.service';
import { Router } from '@angular/router';
import { Location } from '@angular/common';

@Component({
  selector: 'app-view-video',
  templateUrl: './view-video.component.html',
  styleUrls: ['./view-video.component.scss']
})
export class ViewVideoComponent implements OnInit {



  arrLanguage: any = {};
  language: string = '';
  path: string = '';
  idVideo: string;
  nameVideo: string;
  urlVideo: string;

  constructor(
    public locations: Location,
    private tempService: TempService,
    private router: Router,
  ) {
    this.tempService.changePath('video/view')
    var url = window.location.href;
    this.nameVideo = this.getParameterByName('name', url);
    this.urlVideo = this.getParameterByName('url', url);
  }

  ngOnInit() {
    this.tempService.languageObs.subscribe(msg => {
      console.log(msg);
      if (msg != '') {
        console.log('msg is', languages[msg]);
        this.arrLanguage = languages[msg];
        this.language = msg;
      }
    })
    this.tempService.pathObs.subscribe(msg => {
      console.log(msg);
      if (msg != '') {
        console.log('msg is', msg);
        this.path = msg;
      }
    })
  }
  getParameterByName(name, url) {
    if (!url) url = window.location.href;
    name = name.replace(/[\[\]]/g, '\\$&');
    var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
      results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
  }
  back(){
    this.locations.back()
  }
}
