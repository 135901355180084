import { Component } from '@angular/core';
// import { AuthService } from '../core/auth.service'
import { Router, Params } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthService } from '../../core/auth.service';
import { AngularFireFunctions } from '@angular/fire/functions';
import { HttpClient } from '@angular/common/http';
import { TempService } from 'src/app/core/services/temp.service';
import { languages } from 'src/assets/language';
// import { AuthService } from '../core/auth.service';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent {

  registerForm: FormGroup;
  successMessage: string = '';
  sttLoading: boolean = false;
  sttNotifi: boolean = false;
  textNotifi: string;
  sttTextNotifi: string = 'toast-success';
  arrLanguage: any = {};
  language: string;

  dismissToast() {
    this.sttNotifi = false;
  }
  constructor(
    private tempService: TempService,
    public authService: AuthService,
    private router: Router,
    private fb: FormBuilder,
    private fbfs: AngularFireFunctions,
    private http: HttpClient
  ) {
    this.createForm();
    console.log(this.fbfs);

  }

  checkDataSubmit(value) {
    if (value.email == '') {
      // ToastAndroid.show('Invalid email', ToastAndroid.LONG);
      this.sttNotifi = true;
      this.sttTextNotifi = 'toast-error';
      this.sttLoading = false;
      this.textNotifi = 'Invalid email';
      return false;
    }
    if (value.email != value.confirmEmail) {
      // ToastAndroid.show('Email does not match', ToastAndroid.LONG);
      this.sttNotifi = true;
      this.sttTextNotifi = 'toast-error';
      this.sttLoading = false;
      this.textNotifi = 'Email does not match';
      return false;
    }
    if (value.password == '') {
      // ToastAndroid.show('Invalid password', ToastAndroid.LONG);
      this.sttNotifi = true;
      this.sttTextNotifi = 'toast-error';
      this.sttLoading = false;
      this.textNotifi = 'Invalid password';
      return false;
    }
    if (value.password != value.confirmPassword) {
      // ToastAndroid.show('Your password did not match', ToastAndroid.LONG);
      this.sttNotifi = true;
      this.sttTextNotifi = 'toast-error';
      this.sttLoading = false;
      this.textNotifi = 'Your password did not match';
      return false;
    }
    return true;
  }

  async submit(value) {
    console.log(value);
    // this.setState({
    //   sttSubmit: true
    // })
    this.sttLoading = true;

    if (!this.checkDataSubmit(value)) {
      this.sttLoading = false;
      return
    }
    var language;
    switch (this.language) {
      case 'en':
        language = '8'
        break;
      case 'vn':
        language = '9'
        break;
      case 'cn':
        language = '7'
        break;
      case 'hd':
        language = '6'
        break;
      default:
        break;
    }
    var link = "https://us-central1-fingerprinting-camera.cloudfunctions.net/createAccountbccmdmlss";
    var data = JSON.stringify({
      phoneNumber: value.phone,
      email: value.email.toLocaleLowerCase().trim(),
      password: value.password.trim(),
      uid: value.email.toLocaleLowerCase().trim(),
      referralCode: value.referralCode.toLocaleUpperCase().trim(),
      language,
      gender: '0'
    });
    this.http.post(link, data)
      .subscribe(data => {
        console.log('data ', data);
        if (data['stt']) {
          this.sttNotifi = true;
          this.sttLoading = false;
          this.textNotifi = "Your account has been created";
          this.sttTextNotifi = 'toast-success';
          // setTimeout(() => {
          //   this.router.navigate(['/login']);
          // }, 1500);
        }
      }, err => {
        console.log(err);
        this.sttNotifi = true;
        this.sttTextNotifi = 'toast-error';
        this.sttLoading = false;
        this.textNotifi = err.error.msg;
      });
  }

  ngOnInit() {
    this.tempService.languageObs.subscribe(msg => {
      console.log(msg);
      if (msg != '') {
        console.log('msg is', languages[msg]);
        this.arrLanguage = languages[msg];
        this.language = msg;
      }
    })
  }

  createForm() {
    this.registerForm = this.fb.group({
      email: ['', Validators.required],
      confirmEmail: ['', Validators.required],
      password: ['', Validators.required],
      confirmPassword: ['', Validators.required],
      referralCode: ['', Validators.required],
      phone: ['', Validators.required]
    });
  }

}
