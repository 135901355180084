// import { Component } from '@angular/core';
// // import { AuthService } from '/app/core/auth.service'
// import { Router, Params } from '@angular/router';
// import { FormBuilder, FormGroup, Validators } from '@angular/forms';
// import { AuthService } from 'src/app/core/auth.service';
// import { AngularFirestore } from '@angular/fire/firestore';

// @Component({
//   selector: 'page-login',
//   templateUrl: 'login.component.html',
//   styleUrls: ['login.scss']
// })
// export class LoginComponent {

//   loginForm: FormGroup;
//   errorMessage: string = '';
//   sttLoading: boolean = false;
//   constructor(
//     public afs: AngularFirestore,
//     public authService: AuthService,
//     private router: Router,
//     private fb: FormBuilder
//   ) {
//     this.createForm();
//   }

//   createForm() {
//     this.loginForm = this.fb.group({
//       email: ['', Validators.required],
//       password: ['', Validators.required]
//     });
//   }

//   tryFacebookLogin() {
//     this.authService.doFacebookLogin()
//       .then(res => {
//         this.router.navigate(['/home']);
//       })
//   }

//   tryTwitterLogin() {
//     this.authService.doTwitterLogin()
//       .then(res => {
//         this.router.navigate(['/home']);
//       })
//   }

//   tryGoogleLogin() {
//     this.authService.doGoogleLogin()
//       .then(res => {
//         this.router.navigate(['/home']);
//       })
//   }

//   tryLogin(value) {
//     this.sttLoading = true;
//     this.authService.doLogin(value)
//       .then(res => {
//         console.log('res', res);
//         console.log(res.user.email);
//         this.afs.firestore.enableNetwork();
//         window.localStorage.setItem('uid', res.user.uid)
//         this.router.navigate(['/home']);
//         this.sttLoading = false;
//         window.location.reload()
//       }, err => {

//         this.sttLoading = false;
//         this.errorMessage = err.message;
//       })
//   }
// }












import { Component } from '@angular/core';
// import { AuthService } from '../core/auth.service'
import { Router, Params } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthService } from '../../core/auth.service';
import { AngularFireFunctions } from '@angular/fire/functions';
import { HttpClient } from '@angular/common/http';
import { TempService } from 'src/app/core/services/temp.service';
import { languages } from 'src/assets/language';
import { AngularFirestore } from '@angular/fire/firestore';
import { takeWhile } from 'rxjs/operators';
// import { AuthService } from '../core/auth.service';

@Component({
  selector: 'app-login',
  templateUrl: 'login.component.html',
  styleUrls: ['login.scss']
})
export class LoginComponent {

  registerForm: FormGroup;
  successMessage: string = '';
  sttLoading: boolean = false;
  sttNotifi: boolean = false;
  textNotifi: string;
  sttTextNotifi: string = 'toast-success';
  arrLanguage: any = {};
  language: string;

  onChangeLanguage(deviceValue) {
    console.log(deviceValue);
    this.tempService.changeLanguage(deviceValue)
  }
  dismissToast() {
    this.sttNotifi = false;
  }
  constructor(
    public afs: AngularFirestore,
    private tempService: TempService,
    public authService: AuthService,
    private router: Router,
    private fb: FormBuilder,
    private fbfs: AngularFireFunctions,
    private http: HttpClient
  ) {
    this.createForm();
    console.log(this.fbfs);

  }

  checkDataSubmit(value) {
    if (value.email == '') {
      // ToastAndroid.show('Invalid email', ToastAndroid.LONG);
      this.sttNotifi = true;
      this.sttTextNotifi = 'toast-error';
      this.sttLoading = false;
      this.textNotifi = 'Invalid email';
      return false;
    }
    if (value.password == '') {
      // ToastAndroid.show('Invalid password', ToastAndroid.LONG);
      this.sttNotifi = true;
      this.sttTextNotifi = 'toast-error';
      this.sttLoading = false;
      this.textNotifi = 'Invalid password';
      return false;
    }
    return true;
  }

  async submit(value) {
    console.log(value);
    // this.setState({
    //   sttSubmit: true
    // })
    this.sttLoading = true;

    if (!this.checkDataSubmit(value)) {
      this.sttLoading = false;
      return
    }
    this.authService.doLogin(value)
      .then(res => {
        console.log('res', res);
        console.log('res.user.uid ', res.user.uid);
        this.getDataUser(res.user.uid);
        // this.afs.doc('users/' + res.user.uid).valueChanges().subscribe(data => {
        //   console.log(data);
        //   if (data == undefined) {
        //     return window.location.reload()
        //   }
        //   console.log('res', res);
        //   console.log(res.user.email);
        //   this.afs.firestore.enableNetwork();
        //   window.localStorage.setItem('uid', res.user.uid)
        //   this.router.navigate(['/home']);
        //   this.sttLoading = false;
        //   window.location.reload()
        // })
      }, err => {
        this.sttNotifi = true;
        this.sttTextNotifi = 'toast-error';
        this.sttLoading = false;
        this.textNotifi = err.message;
        // this.errorMessage = err.message;
      })
  }

  getDataUser(uid) {
    console.log(uid);

    let sttGet = true;
    var data = this.afs.doc('users/' + uid);
    data.valueChanges()
      .pipe(takeWhile(() => sttGet))
      .subscribe(data => {
        // JSON.stringify(data)
        if (data == undefined) {
          console.log(JSON.stringify(data));
          window.localStorage.setItem('dataUser', JSON.stringify(data))
          setTimeout(() => {
            this.router.navigate(['/home']);
            window.location.reload();
            this.sttLoading = false;
          }, 1500);
          return;
        }
        console.log(JSON.stringify(data));
        window.localStorage.setItem('dataUser', JSON.stringify(data))
        this.router.navigate(['/home']);
        window.location.reload();
        this.sttLoading = false;

        // window.location.reload();
      }, error => {
        console.log(error);
      })
  }

  ngOnInit() {
    this.tempService.languageObs.subscribe(msg => {
      console.log(msg);
      if (msg != '') {
        console.log('msg is', languages[msg]);
        this.arrLanguage = languages[msg];
        this.language = msg;
      }
    })
  }

  createForm() {
    this.registerForm = this.fb.group({
      email: ['', Validators.required],
      password: ['', Validators.required]
    });
  }
}
