import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { rootRouterConfig } from './app.routes';
import { AngularFireModule } from '@angular/fire';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireStorageModule } from '@angular/fire/storage';
import { environment } from '../environments/environment';
import { LoginComponent } from './outside/login/login.component';
import { UserComponent } from './user/user.component';
import { RegisterComponent } from './outside/register/register.component';
import { UserResolver } from './user/user.resolver';
import { AuthGuard } from './core/auth.guard';
import { AuthService } from './core/auth.service';
import { UserService } from './core/user.service';
import { ReactiveFormsModule } from '@angular/forms';
import { AngularFireFunctionsModule } from '@angular/fire/functions';

import { AppComponent } from './app.component';
import { AgencyService } from './core/agency.service';
import { NhapBangDeService } from './core/nhap-bang-de.service';
import { HttpClientModule } from '@angular/common/http';
import { ForgotPasswordComponent } from './outside/forgot-password/forgot-password.component';
import { DemoMaterialModule } from './material-module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ViewVideoComponent } from './outside/view-video/view-video.component';
import { MatVideoModule } from 'mat-video';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    UserComponent,
    RegisterComponent,
    ForgotPasswordComponent,
    ViewVideoComponent
  ],
  imports: [
    MatVideoModule,
    BrowserAnimationsModule,
    BrowserModule,
    DemoMaterialModule,
    ReactiveFormsModule,
    HttpClientModule,
    RouterModule.forRoot(rootRouterConfig, { useHash: false }),
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireFunctionsModule,
    AngularFirestoreModule, // imports firebase/firestore, only needed for database features
    AngularFireAuthModule, // imports firebase/auth, only needed for auth features
    AngularFireStorageModule
  ],
  entryComponents: [LoginComponent, ViewVideoComponent],
  providers: [AuthService, UserService, NhapBangDeService, AgencyService, UserResolver, AuthGuard],
  bootstrap: [AppComponent]
})
export class AppModule { }
