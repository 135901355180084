import { Routes } from '@angular/router';

// import { LoginComponent } from './login/login.component';
import { UserComponent } from './user/user.component';
import { RegisterComponent } from './outside/register/register.component';
import { UserResolver } from './user/user.resolver';
import { AuthGuard } from './core/auth.guard';
import { LoginComponent } from './outside/login/login.component';
import { ForgotPasswordComponent } from './outside/forgot-password/forgot-password.component';
import { ViewVideoComponent } from './outside/view-video/view-video.component';

export const rootRouterConfig: Routes = [
  { path: '', redirectTo: 'login', pathMatch: 'full' },
  { path: 'login', component: LoginComponent, canActivate: [AuthGuard] },
  { path: 'forgot_password', component: ForgotPasswordComponent, canActivate: [AuthGuard] },
  { path: 'register', component: RegisterComponent, canActivate: [AuthGuard] },
  { path: 'video/view', component: ViewVideoComponent},
  { path: 'user', component: UserComponent, resolve: { data: UserResolver } },
  {
    path: '',
    loadChildren: './inside/inside.module#InsidePageModule'
  }
];
