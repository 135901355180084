import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class TempService {

    language$: BehaviorSubject<string> = new BehaviorSubject<string>('vn');
    languageObs: Observable<string> = this.language$.asObservable();

    path$: BehaviorSubject<string> = new BehaviorSubject<string>('');
    pathObs: Observable<string> = this.path$.asObservable();

    constructor() { }
    changeLanguage(value: string) {
        this.language$.next(value);
    }
    changePath(value: string) {
        this.path$.next(value);
    }
}
