
import { Injectable } from "@angular/core";
import 'rxjs/add/operator/toPromise';
import { AngularFireAuth } from '@angular/fire/auth';
import * as firebase from 'firebase/app';
import { FirebaseNhapBangDe } from './nhap-bang-de.model';
import { AngularFirestore } from '@angular/fire/firestore';

@Injectable()
export class NhapBangDeService {

  constructor(
   public afAuth: AngularFireAuth,
   private afs: AngularFirestore,
 ){

 }

 doCreateNhapBangDe (newNhapBangDe: FirebaseNhapBangDe) {
     // TODO: create a user with email and password
    return this.afs.collection('nhapBangDe').doc(this.afs.createId()).set(newNhapBangDe)
    .then(regData => {
        console.log(regData);
        // TODO: if create success, insert newAgency to agency table
        return true;
    })
    .catch(err => {
        console.log(err);
        return false;
    })
    
  }

}