// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: true,
  firebase: {
    apiKey: "AIzaSyBUsVzfWrFvVDY4A_WDQ258EXkWVTxsw5Q",
    authDomain: "fingerprinting-camera.firebaseapp.com",
    databaseURL: "https://fingerprinting-camera.firebaseio.com",
    projectId: "fingerprinting-camera",
    storageBucket: "fingerprinting-camera.appspot.com",
    messagingSenderId: "161633370429",
    appId: "1:161633370429:web:cd00556de38b61a98ec554"
  }
};
