
import { Injectable } from "@angular/core";
import 'rxjs/add/operator/toPromise';
import { AngularFireAuth } from '@angular/fire/auth';
import * as firebase from 'firebase/app';
import { FirebaseAgency } from './agency.model';
import { AngularFirestore } from '@angular/fire/firestore';

@Injectable()
export class AgencyService {

  constructor(
   public afAuth: AngularFireAuth,
   private afs: AngularFirestore,
 ){

 }

 doCreateAgency (newAgency: FirebaseAgency, password: string) {
     // TODO: create a user with email and password
    return this.afAuth.auth.createUserWithEmailAndPassword(newAgency.email, password)
    .then(regData => {
        console.log(regData);
        // TODO: if create success, insert newAgency to agency table
    })
    .then(() => this.afs.collection('account').doc(newAgency.bossEmail).collection('agency').doc(newAgency.email).set(newAgency))
    .then(data => {return true})
    .catch(err => {
        console.log(err);
        return false;
    })
    
  }

}