export const languages = {
    vn: {
        hello: 'Hellossss',
        dangnhap: 'Đăng nhập',
        tao: 'Tạo',
        gioitinh: 'Giới tính',
        nu: 'Nữ',
        nam: 'Nam',
        taotaikhoan: 'Tạo tài khoản',
        matkhau: 'Mật khẩu',
        xacnhanmatkhau: 'Xác nhận mật khẩu',
        magioithieu: 'Mã giới thiệu',
        ngonngu: 'Ngôn ngữ',
        email: 'Email',
        quenmatkhau: 'Quên mật khẩu',
        tennganhang: 'Tên ngân hàng',
        ten: 'Tên',
        sotaikhoan: 'Số tài khoản',
        noidung: 'Nội dung',
        tentaikhoan: 'Tên tài khoản',
        nhapten: 'Nhập tên',
        nhapsodienthoai: 'Nhập số điện thoại',
        thanhpho: 'Thành phố',
        nhapthanhpho: 'Nhập thành phố',
        huyen: 'Huyện',
        nhaphuyen: 'Nhập huyện',
        khuvuc: 'Khu vực',
        nhapkhuvuc: 'Nhập khu vực',
        diachichitiet: 'Địa chỉ chi tiết',
        nhapdiachichitiet: 'Nhập địa chỉ chi tiết',
        ngaysinh: 'Năm sinh',
        sodienthoai: 'Số điện thoại',
        guidi: 'Gửi đi',
        nhapngaysinh: 'VD: 2000',
        nhapemail: 'Nhập email',
        hinhthuctraketqua: 'Hình thức trả kết quả',
        hinhthucthanhtoan: 'Hình thức thanh toán',
        sudungvoucherhoacmacode: 'Sử dụng voucher hoặc mã code',
        trongungdung: 'Ứng dụng',
        cod: 'cod',
        nganhang: 'Ngân hàng',
        thedienthoai: 'Thẻ điện thoại',
        vi: 'Ví',
        tructiep: 'Trực tiếp',
        dong1tructiep: 'Thanh toán khi nhận được hàng',
        dong2tructiep: 'Phí thu hộ: 22400 VNĐ. Ưu đãi về phí vận chuyển (nếu có) áp dụng cả với phí thu hộ.',
        xacnhan: 'Xác nhận',
        diachinhanhang: 'Địa chỉ nhận hàng',
        vanchuyennhanh: 'Vận chuyển nhanh',
        giaohangtietkiem: 'Giao hàng tiết kiệm',
        nhanhangtu3den5ngay: 'Nhận hàng từ 3 - 5 ngày',
        tinnhan: 'Tin nhắn',
        luuychonguoichuyen: 'Lưu ý cho người chuyển',
        tongsotien: 'Tổng số tiền (1 sản phẩm)',
        baomat: 'Bảo mật',
        thaydoimatkhau: 'Thay đổi mật khẩu',
        hoso: 'Hồ sơ',
        xacthucthongtin: 'Xác thực thông tin',
        matkhauhientai: 'Mật khẩu hiện tại',
        matkhaumoi: 'Mật khẩu mới',
        quaylai: 'Quay lại',
        trangchu: 'Trang chủ',
        caidat: 'Cài đặt',
        dangxuat: 'Đăng xuất',
        thanhtoan: 'Thanh toán',
        baocao: 'Báo cáo',
        danhsachbaocao: 'Danh sách báo cáo',
        hoten: 'Họ tên',
        mabaocao: 'Mã báo cáo',
        thongbao: 'Thông báo',
        thoigian: 'Thời gian',
        tieude: 'Tiêu đề',
        danhsachthongbao: 'Danh sách thông báo',
        chuy: 'Chú ý',
        chuychuyentien: 'Xin vui lòng chuyển tiền vào tài khoản với nội dung trên.',
        sodu: 'Số dư',
        sotiencanthanhtoan: 'Số tiền cần thanh toán',
        sotientrongvi: 'Số tiền trong ví',
        trangthai: 'Trạng thái',
        Home: 'Trang chủ',
        Selection: 'Lựa chọn',
        Profile: 'Hồ sơ',
        Report: 'Báo cáo',
        Notification: 'Thông báo',
        CreateReport: 'Tạo báo cáo',
        About: 'Video',
        moi: 'Mới',
        huongdan: 'Hướng dẫn',
        sinhtracvantay: 'Sinh trắc vân tay',
        sachhay: 'Sách hay',
        video: 'Video',
        dksd: 'Đồng ý điều khoản và dịch vụ',
        confirmEmail: 'Xác nhận email',
        home1: 'Đọc kỹ hướng dẫn trước khi Scan',
        daily: 'Đại lý',
        ghinhomatkhau: 'Ghi nhớ mật khẩu',
        phone: 'Số điện thoại'
    },
    en: {
        hello: 'Konnichiwasss',
        dangnhap: 'Sign In',
        tao: 'Create',
        gioitinh: 'Gender',
        nu: 'Female',
        nam: 'Male',
        taotaikhoan: 'Create account',
        matkhau: 'Password',
        xacnhanmatkhau: 'Confirm Password',
        magioithieu: 'Referral code',
        ngonngu: 'Language',
        email: 'Email',
        quenmatkhau: 'Forgot password',
        tennganhang: 'Bank name',
        ten: 'Name',
        sotaikhoan: 'Account Number',
        noidung: 'content',
        tentaikhoan: 'Name account',
        nhapten: 'Set name',
        nhapsodienthoai: 'Set phone number',
        thanhpho: 'City',
        nhapthanhpho: 'Set City',
        huyen: 'District',
        nhaphuyen: 'Set District',
        khuvuc: 'Ward',
        nhapkhuvuc: 'Set Ward',
        diachichitiet: 'Detailed Address',
        nhapdiachichitiet: 'Set Detailed Address',
        ngaysinh: 'Birthday',
        sodienthoai: 'Phone number',
        guidi: 'Submit',
        nhapemail: 'Set email',
        hinhthuctraketqua: 'pay results method',
        hinhthucthanhtoan: 'payments method',
        sudungvoucherhoacmacode: 'Use voucher or enter code',
        trongungdung: 'Pay in app',
        cod: 'cod',
        nganhang: 'Banking',
        thedienthoai: 'Phone card',
        vi: 'Wallet',
        tructiep: 'direct',
        dong1tructiep: 'Payment on delivery',
        dong2tructiep: 'Collection fee: VND 22400. Freight incentives (if any) apply to collection fees.',
        xacnhan: 'Confirm',
        diachinhanhang: 'Shipping Address',
        vanchuyennhanh: 'Fast Shipping',
        giaohangtietkiem: 'Savings delivery',
        nhanhangtu3den5ngay: 'Receive goods from 3-5 days',
        tinnhan: 'Message',
        luuychonguoichuyen: 'Note to the transfer',
        tongsotien: 'Total amount (1 product)',
        baomat: 'Protect',
        thaydoimatkhau: 'Change the password',
        hoso: 'Profile',
        xacthucthongtin: 'Verify information',
        matkhauhientai: 'Current password',
        matkhaumoi: 'New password',
        quaylai: 'Come back',
        trangchu: 'Home',
        caidat: 'Settings',
        dangxuat: 'Logout',
        thanhtoan: 'Pay',
        baocao: 'Report',
        danhsachbaocao: 'Report list',
        hoten: 'Full name',
        mabaocao: 'Report code',
        thongbao: 'Notification',
        thoigian: 'Time',
        tieude: 'Title',
        danhsachthongbao: 'Notifications list',
        chuy: 'Note',
        chuychuyentien: 'Please transfer money to the account with the above content.',
        nhapngaysinh: 'VD: 2000',
        sodu: 'Balance',
        sotiencanthanhtoan: 'Amount to pay',
        sotientrongvi: 'Money in wallet',
        trangthai: 'Status',
        Home: 'Home',
        Selection: 'Selection',
        Profile: 'Profile',
        Report: 'Report',
        Notification: 'Notification',
        CreateReport: 'CreateReport',
        About: 'Video',
        moi: 'New',
        huongdan: 'Tutorial',
        sinhtracvantay: 'Fingerprint biometric',
        sachhay: 'Good book',
        video: 'Video',
        dksd: 'Agree to terms and services',
        confirmEmail: 'Confirm email',
        home1: 'Read the instructions carefully before scanning',
        daily: 'Agency',
        ghinhomatkhau: 'Remember password',
        phone: 'Phone number'
    },
    cn: {
        hello: 'Bonjoursss',
        dangnhap: '登入',
        tao: '创造',
        gioitinh: '性别',
        nu: '女',
        nam: '男',
        taotaikhoan: '创建帐号',
        matkhau: '密码',
        xacnhanmatkhau: '确认密码',
        magioithieu: '推荐码',
        ngonngu: '语言',
        email: '电子邮件',
        quenmatkhau: '忘记密码',
        tennganhang: '银行名称',
        ten: '名称',
        sotaikhoan: '帐号',
        noidung: '内容',
        tentaikhoan: '名称帐号',
        nhapten: '设置名称',
        nhapsodienthoai: '设置电话号码',
        thanhpho: '市',
        nhapthanhpho: '设置城市',
        huyen: '区',
        nhaphuyen: '设置区',
        khuvuc: '病房',
        nhapkhuvuc: '设置病房',
        diachichitiet: '详细地址',
        nhapdiachichitiet: '设置详细地址',
        ngaysinh: '生日',
        sodienthoai: '电话号码',
        guidi: '提交',
        nhapemail: '设定电子邮件',
        hinhthuctraketqua: '支付结果方法',
        hinhthucthanhtoan: '付款方式',
        sudungvoucherhoacmacode: '使用优惠券或输入代码',
        trongungdung: '支付应用',
        cod: 'cod',
        nganhang: '银行业',
        thedienthoai: '电话卡',
        vi: '钱包',
        tructiep: '直接',
        dong1tructiep: '货到付款',
        dong2tructiep: '代收费用：VND22400。货运奖励（如有）适用于代收费用。',
        xacnhan: '确认',
        diachinhanhang: '送货地址',
        vanchuyennhanh: '快速送货',
        giaohangtietkiem: '储蓄交付',
        nhanhangtu3den5ngay: '3-5天内收到货物',
        tinnhan: '信息',
        luuychonguoichuyen: '转移注意',
        tongsotien: '总计（1个产品）',
        baomat: '保护',
        thaydoimatkhau: '修改密码',
        hoso: '轮廓',
        xacthucthongtin: '验证信息',
        matkhauhientai: '当前密码',
        matkhaumoi: '新密码',
        quaylai: '回来',
        trangchu: '家',
        caidat: '设定值',
        dangxuat: '登出',
        thanhtoan: '工资',
        baocao: '报告',
        danhsachbaocao: '报告清单',
        hoten: '全名',
        mabaocao: '报告代码',
        thongbao: '通知',
        thoigian: '时间',
        tieude: '标题',
        danhsachthongbao: '通知清单',
        chuy: '注意',
        chuychuyentien: '请转帐到具有上述内容的帐户。 收到存款后5到10便士，VND将显示在您的电子钱包中。',
        nhapngaysinh: 'VD：2000',
        sodu: '平衡',
        sotiencanthanhtoan: '支付数量',
        sotientrongvi: '钱包里的钱',
        trangthai: '状态',
        Home: '家',
        Selection: '选拔',
        Profile: '轮廓',
        Report: '报告',
        Notification: '通知',
        CreateReport: '创建报告',
        About: '视频',
        moi: '新',
        huongdan: '讲解',
        sinhtracvantay: '指纹生物识别',
        sachhay: '好书',
        video: '视频',
        dksd: '同意条款和服务',
        confirmEmail: '确认邮件',
        home1: '扫描前请仔细阅读说明',
        daily: '机构',
        ghinhomatkhau: '记住密码',
        phone: '电话号码'
    },
    hd: {
        hello: 'G\'day!ss',
        dangnhap: 'लॉग इन',
        tao: 'बनाने',
        gioitinh: 'लिंग',
        nu: 'महिला',
        nam: 'पुरुष',
        taotaikhoan: 'खाता बनाएं',
        matkhau: 'कुंजिका',
        xacnhanmatkhau: 'पासवर्ड की पुष्टि कीजिये',
        magioithieu: 'रेफरल कोड',
        ngonngu: 'भाषा',
        email: 'ईमेल',
        quenmatkhau: 'पासवर्ड भूल गए',
        tennganhang: 'बैंक का नाम',
        ten: 'नाम',
        sotaikhoan: 'खाता संख्या',
        noidung: 'सामग्री',
        tentaikhoan: 'नाम खाता',
        nhapten: 'नाम भरें',
        nhapsodienthoai: 'फ़ोन नंबर सेट करें',
        thanhpho: 'City',
        nhapthanhpho: 'सिटी सेट करें',
        huyen: 'जिला',
        nhaphuyen: 'जिला निर्धारित करें',
        khuvuc: 'परवरिश',
        nhapkhuvuc: 'वार्ड निर्धारित करें',
        diachichitiet: 'विस्तृत पता',
        nhapdiachichitiet: 'विस्तृत पता सेट करें',
        ngaysinh: 'जन्मदिन',
        sodienthoai: 'फ़ोन नंबर',
        guidi: 'प्रस्तुत',
        nhapemail: 'ईमेल सेट करें',
        hinhthuctraketqua: 'परिणाम विधि का भुगतान करें',
        hinhthucthanhtoan: 'भुगतान विधि',
        sudungvoucherhoacmacode: 'वाउचर का उपयोग करें या कोड दर्ज करें',
        trongungdung: 'अनुप्रयोग में वेतन',
        cod: 'cod',
        nganhang: 'बैंकिंग',
        thedienthoai: 'फोन कार्ड',
        vi: 'बटुआ',
        tructiep: 'प्रत्यक्ष',
        dong1tructiep: 'वितरण करने पर भुगतान',
        dong2tructiep: 'संग्रह शुल्क: VND 22400। भाड़ा प्रोत्साहन (यदि कोई हो) संग्रह शुल्क पर लागू होता है।',
        xacnhan: 'पुष्टि',
        diachinhanhang: 'शिपिंग पता',
        vanchuyennhanh: 'तेज नौपरिवहन',
        giaohangtietkiem: 'बचत वितरण',
        nhanhangtu3den5ngay: '3-5 दिनों से माल प्राप्त करें',
        tinnhan: 'संदेश',
        luuychonguoichuyen: 'हस्तांतरण पर ध्यान दें',
        tongsotien: 'कुल राशि (1 उत्पाद)',
        baomat: 'रक्षा करना',
        thaydoimatkhau: 'पासवर्ड बदलें',
        hoso: 'प्रोफ़ाइल',
        xacthucthongtin: 'जानकारी सत्यापित करें',
        matkhauhientai: 'वर्तमान पासवर्ड',
        matkhaumoi: 'नया पासवर्ड',
        quaylai: 'वापस लौटें',
        trangchu: 'घर',
        caidat: 'समायोजन',
        dangxuat: 'लॉग आउट',
        thanhtoan: 'वेतन',
        baocao: 'रिपोर्ट',
        danhsachbaocao: 'रिपोर्ट सूची',
        hoten: 'पूरा नाम',
        mabaocao: 'रिपोर्ट कोड',
        thongbao: 'अधिसूचना',
        thoigian: 'समय',
        tieude: 'शीर्षक',
        danhsachthongbao: 'अधिसूचना सूची',
        chuy: 'ध्यान दें',
        chuychuyentien: 'कृपया उपरोक्त सामग्री के साथ खाते में धनराशि स्थानांतरित करें। वीपीएन आपके वॉलेट पर 5-10 पी के बाद जमा हो जाएगा।',
        nhapngaysinh: 'वीडी: 2000',
        sodu: 'संतुलन',
        sotiencanthanhtoan: 'भुगतान की जाने वाली राशि',
        sotientrongvi: 'वॉलेट में पैसा',
        trangthai: 'स्थिति',
        Home: 'घर',
        Selection: 'चयन',
        Profile: 'प्रोफ़ाइल',
        Report: 'रिपोर्ट',
        Notification: 'अधिसूचना',
        CreateReport: 'रिपोर्ट बनाएं',
        About: 'वीडियो',
        moi: 'नया',
        huongdan: 'ट्यूटोरियल',
        sinhtracvantay: 'बायोमेट्रिक फिंगरप्रिंट',
        sachhay: 'अच्छी किताब',
        video: 'वीडियो',
        dksd: 'शर्तों और सेवाओं के लिए सहमत',
        confirmEmail: 'ईमेल की पुष्टि करें',
        home1: 'स्कैनिंग से पहले निर्देशों को ध्यान से पढ़ें',
        daily: 'एजेंसी',
        ghinhomatkhau: 'पासवर्ड याद रखें',
        phone: 'फ़ोन नंबर'
    }
}