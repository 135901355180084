import { Component, ViewEncapsulation } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { Router } from '@angular/router';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class AppComponent {
    constructor(public afAuth: AngularFireAuth, private router: Router) {
        this.afAuth.authState.subscribe(user => {
            console.log(user);
            if (user != null) {
                console.log(user.uid);
                window.localStorage.setItem('uid', user.uid)
            } else {
                this.router.navigate(['/login']);
            }
        })
    }
}
